<template>
  <v-app>
    <div>

      <div class="fullScreen">

        <v-overlay :absolute="absolute" :value="overlay" color="#fff" opacity="1">
          <v-card class="mx-auto mb-4" max-width="344" color="white" elevation="0">
            <div class="grid-container">
              <div>
                <div class="resultIconContainer" style="border-radius:50%" :class="getBackgroundColour(this.result)">
                  <v-icon dark x-large color="#f28482" v-if="this.result != 'Correct'">
                    mdi-close
                  </v-icon>

                  <v-icon dark x-large color="#90be6d" v-if="this.result == 'Correct'">
                    mdi-check
                  </v-icon>
                </div>
              </div>
              <div>
                <v-card-text>
                  <h6>
                    {{ this.result }}
                  </h6>

                  <div class="text--primary">
                    <b>{{ this.wrongRecap[0] }}</b>
                  </div>
                  <div class="text--primary">
                    {{ this.wrongRecap[1] }}
                  </div>
                  <div class="text--primary">
                    {{ this.wrongRecap[2] }}
                  </div>

                </v-card-text>
              </div>
              <div>
                <v-btn v-if="!this.ended" dark x-large color="#90be6d" elevation=0 @click="overlay = false">
                  Next Question
                </v-btn>
                <v-btn v-if="this.ended" dark x-large color="#90be6d" elevation=0 @click="overlay = false">
                  Get Result
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-overlay>

        <h2 class="domainHeaderMobile" style="padding-top:40px;font-family: 'M PLUS Rounded 1c', sans-serif;"><Emoji name='russiaflag'/> LearnRussianCases</h2>

        <div class="indexBoxContent">
          <div class="dollContainer">
              <img src="../assets/doll.png" style="height:400px;">
          </div>

          <h1 class="ma-0" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'ml-0' : 'ml-1'">Привет!</h1>
          <h3 class="ma-2" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'ml-2' : 'ml-1'">Learn Russian cases in a natural way</h3>
          <h3 class="ma-2" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'ml-2' : 'ml-1'">Real sentences to practice today</h3>

          <div class="googleButtonMobile">
            <v-img v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
            class="googleButton ma-4 ml-0"
            max-width='191'
            max-height='46'
            src="~@/assets/googlesigninbutton.png"
            @click="googleSignIn"></v-img>

            <v-img v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            class="googleButton ma-2 ml-2"
            max-width='191'
            max-height='46'
            src="~@/assets/googlesigninbutton.png"
            @click="googleSignIn"></v-img>

            <v-btn color="#ffbe0b" height="40px" elevation=0 @click="$vuetify.goTo('#demo', options)">Try a Demo</v-btn>
          </div>
          <div class="signInInfo">
            By signing in, you agree to our <br v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"><router-link to="/terms">Terms &amp; Conditions</router-link> and <router-link to="/privacy">Privacy Policy</router-link>.
          </div>

          <div class="dollContainerMobile">

          </div>

        </div>


        <!-- <div class="arrowContainer" style="padding: 0 0 20px 0;text-align: center;bottom:0;">
          <img src="../assets/arrowdown.svg" class="arrowJump" style="width:40px;" @click="$vuetify.goTo('#scrollTo', options)">
        </div> -->





      </div>

      <div class="fixedBanner">

        <a target="_blank" href="https://apps.apple.com/gb/app/learnrussiancases/id6450753430">
        <div class="bannerContent">
          <div style="text-align: left;">
            <h2><Emoji name='apple'/> iOS App</h2>
            <p>LearnRussianCases<br>is now available on iOS!</p>
          </div>

          <div>
            <img src="../assets/iOSscreenshot4.png" style="width:140px; border-radius: 10px; border: 2px solid #e5e5e5;">
            <img src="../assets/iOSscreenshot3.png" style="width:140px; border-radius: 10px; border: 2px solid #e5e5e5; margin-left: -20px;">
            <img src="../assets/iOSscreenshot2.png" style="width:140px; border-radius: 10px; border: 2px solid #e5e5e5; margin-left: -20px;">
            <img src="../assets/iOSscreenshot1.png" style="width:140px; border-radius: 10px; border: 2px solid #e5e5e5; margin-left: -20px;">
          </div>

          <a target="_blank" href="https://apps.apple.com/gb/app/learnrussiancases/id6450753430"><img src="https://pianodash.com/images/appStore.svg" style="width: 200px;"></a>
        </div>
        </a>


      </div>


      <div style="height:140px;"></div>


      <hr style="margin-top:0;margin-bottom:0px;padding-top:0;">



      <div id="scrollTo" :class="isOverlay()">

        <div class="hideOnMobile">
          <div class="indexBox" style="text-align:left;">
            <img src="../assets/lesson.png" class="ml-6 mr-6" style="height:240px;float:left;top:2em;position:relative;">
            <div class="mt-4">
              <h3 class="mb-4">Learn</h3>
              <p>Russian cases can seem overwhelming!</p>
              <p>We'll teach you when and how to use each case with 38 easily-digestible lessons.</p>
            </div>
          </div>

          <hr>

          <div class="indexBox" style="text-align:right;justify-content:right;">
            <div>
              <h3 class="mb-4">Practice</h3>
              <p>Answer varied questions to gauge your understanding.</p>
              <p>Use real Russian with lots of high-quality content to practice.</p>
              <p>Learn from your mistakes with instant feedback.</p>
            </div>
            <img src="../assets/dashboard.png" style="height:240px;float:right;">
          </div>

          <hr>

          <div class="indexBox" style="text-align:left;">
            <IndexChart :freshnessValues='this.freshnessValues' />
            <div class="mobileTextInfo ml-6">
              <h3 class="mb-4">Progress</h3>
              <p>Track your progress though each lesson.</p>
              <p>Check your Freshness
                <v-tooltip bottom opacity="1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="left:-3px;top:-6px;"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>This represents how likely it is that you<br> can answer a question in a given case</span>
                </v-tooltip>

                 chart to see which cases you should practice.</p>
            </div>
          </div>

          <hr>

          <div style="text-align:center;padding:0;margin:0;display:block;">
            <div class="">
              <h3 class="mb-4">Learn Anywhere</h3>
              <p>Fully optimised for desktop and mobile, so you can learn cases everywhere you go!</p>
            </div>
            <img src="../assets/optimised.png" style="height:240px;margin:0;padding:0;top:6px;position:relative;">
          </div>
        </div>




        <!-- Mobile: -->
        <div class="hideOnDesktop">
          <div class="indexBox" style="padding-bottom:0;">
            <div class="mobileTextInfo mt-2">
              <h4>Learn</h4>
              <p>Russian cases can seem overwhelming!</p>
              <p>We'll teach you when and how to use each case with 38 easily-digestible lessons.</p>
            </div>
            <img src="../assets/lesson.png" :style="$vuetify.breakpoint.sm ? 'width:34vw' : 'width:70vw'">
          </div>

          <hr style="margin-top:0;">

          <div class="indexBox mb-3">
            <div class="mobileTextInfo">
              <h4>Practice</h4>
              <p>Answer varied questions to gauge your understanding.</p>
              <p>Use real Russian with lots of high-quality content.</p>
              <p>Learn from your mistakes with instant feedback.</p>
            </div>
            <img src="../assets/dashboard.png" :style="$vuetify.breakpoint.sm ? 'width:50vw' : 'width:90vw'" style="float:right;">
          </div>

          <hr>

          <div class="indexBox">
            <IndexChart class="hideOnMobile" :freshnessValues='this.freshnessValues' />
            <div class="mobileTextInfo">
              <h4>Progress</h4>
              <p>Track your progress through each lesson.</p>
              <p>Check your Freshness
                <v-tooltip bottom opacity="1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="left:-3px;top:-6px;"
                    >
                      mdi-help-circle
                    </v-icon>
                  </template>
                  <span>This represents how likely it is that you<br> can answer a question in a given case</span>
                </v-tooltip>

                 chart to see which cases you should practice.</p>
            </div>
            <IndexChart class="hideOnDesktop" :freshnessValues='this.freshnessValues' />
          </div>

          <hr>

          <div class="indexBox" style="padding-bottom:0;">

            <div class="">
              <h4>Learn Anywhere</h4>
              <p>Fully optimised for desktop and mobile, so you can learn cases everywhere you go!</p>
            </div>
            <img src="../assets/optimised.png" :style="$vuetify.breakpoint.sm ? 'width:50vw' : 'width:90vw'" style="margin:0;padding:0;">
          </div>
        </div>










        <hr style="margin-top:0;margin-bottom:0px;padding-top:0;">

        <div id="demo" style="margin-top:15px;height:1px;"></div>

        <div class="mt-4 demoBox">

          <h2 class="mt-0 hideOnMobile">Demo</h2>
          <h4 class="mt-0 hideOnDesktop">Demo</h4>

          <p>Each practice session contains 10 questions.

            <hr>

          <div v-if="!this.ended" class="progressContainer">
            <div class="scoreBar">
              <v-progress-linear v-model="score" rounded height="10" color="green lighten-2"></v-progress-linear>
            </div>
            <div class="livesRemaining">
              <span v-for="(life, index) in lives" :key="`life-${index}`">&nbsp;&nbsp;<Emoji name="heart" /></span>
            </div>
          </div>


          <h2 v-if="this.ended && this.overallResult == 'win'">Well done! <Emoji name="teethsmile" /></h2>
          <h2 v-if="this.ended && this.overallResult == 'lose'">You ran out of lives! <Emoji name="cry" /></h2>

          <h3 v-if="this.ended && this.overallResult == 'win'" class="mt-4"></h3>
          <h3 v-if="this.ended && this.overallResult == 'lose'" class="mt-4">LearnRussianCases can help you to improve</h3>

          <v-btn v-if="this.ended" @click="resetDemo()" color="primary" dark elevation=0 class="mt-6 mb-2">Try again</v-btn>

          <h3 v-if="!this.ended" class="hideOnMobile">{{ alterQuestion(this.questions[this.currentQuestion].question) }}</h3>
          <h3 v-if="!this.ended" class="hideOnDesktop">{{ alterQuestion(this.questions[this.currentQuestion].question) }}</h3>
          <h2 v-if="!this.ended">{{ this.questions[this.currentQuestion].russian }}</h2>
          <h3 v-if="!this.ended">{{ this.questions[this.currentQuestion].english }}</h3>

          <br v-if="!this.ended">

          <div v-if="!this.ended && !overlay" class="inputHeight">
            <span v-if="this.keyboardInput != true || this.questions[this.currentQuestion].canType == false">

              <button v-if="this.buttons[0] != null" v-ripple style="background-color: #90be6d;" class="materialDesignButton pl-6 pr-6 pa-4 ma-2" @click="checkAnswer(buttons[0])">{{ buttons[0] }}</button>
              <button v-if="this.buttons[1] != null" v-ripple style="background-color: #00b4d8;" class="materialDesignButton pl-6 pr-6 pa-4 ma-2" @click="checkAnswer(buttons[1])">{{ buttons[1] }}</button>
              <button v-if="this.buttons[2] != null" v-ripple style="background-color: #ffbe0b;" class="materialDesignButton pl-6 pr-6 pa-4 ma-2" @click="checkAnswer(buttons[2])">{{ buttons[2] }}</button>
              <button v-if="this.buttons[3] != null" v-ripple style="background-color: #e73265;" class="materialDesignButton pl-6 pr-6 pa-4 ma-2" @click="checkAnswer(buttons[3])">{{ buttons[3] }}</button>
              <button v-if="this.buttons[4] != null" v-ripple style="background-color: #ada7c9;" class="materialDesignButton pl-6 pr-6 pa-4 ma-2" @click="checkAnswer(buttons[4])">{{ buttons[4] }}</button>
              <button v-if="this.buttons[5] != null" v-ripple style="background-color: #403d39;" class="materialDesignButton pl-6 pr-6 pa-4 ma-2" @click="checkAnswer(buttons[5])">{{ buttons[5] }}</button>

            </span>

            <div v-if="this.keyboardInput == true && !this.ended && !this.questions[this.currentQuestion].canType == false" class="keyboardInput">
              <v-text-field hide-details outlined elevation='0' v-model="keyboardEntered" v-on:keyup.enter="checkAnswer(keyboardEntered)"></v-text-field>
            </div>
          </div>


          <br v-if="!this.ended && this.questions[this.currentQuestion].canType"><br v-if="!this.ended">


          <v-btn
              v-if="this.questions[this.currentQuestion].canType && !this.ended"
              class="mx-2"
              fab
              dark
              width="64px"
              height="64px"
              color="primary"
              elevation="2"
              @click="changeInput()"
            >
              <v-icon v-if="!this.keyboardInput" dark x-large>
                mdi-keyboard
              </v-icon>
              <v-icon v-if="this.keyboardInput" dark x-large>
                mdi-keyboard-off
              </v-icon>
            </v-btn>


        </div>


      <div class="indexBox whatNextBox mt-6 pb-2">

        <img src="../assets/team.png" style="height:64px;margin:10px;">

        <p>LearnRussianCases is created by <br v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"><Emoji name="ukflag" /> Joseph and <Emoji name="russiaflag" /> Anna <Emoji name="peacesign" /></p>

        <!-- <table>
          <col style="width:50%">
          <col style="width:50%">
          <thead>
            <tr>
              <th>$5 Monthly</th>
              <th>$25 Yearly
                <v-chip v-if="!$vuetify.breakpoint.xs" small style="left:3px;top:-10px;" color="green lighten-2" dark>Save 58%</v-chip>
                <v-chip v-if="$vuetify.breakpoint.xs" small color="green lighten-2" dark>Save 58%</v-chip>
              </th>
            </tr>
          </thead>
        </table> -->

        <v-img
        class="googleButton ma-4 ml-0 mt-5"
        max-width='191'
        max-height='46'
        src="~@/assets/googlesigninbuttondark.png"
        @click="googleSignIn"></v-img>


      </div>






      <br><br>


      <div class="indexBox">
        <img src="../assets/blini.png" style="height:160px;margin:40px auto;">
      </div>

    </div>


    <div class="legalPages">
      <router-link to="/terms">Terms &amp; Conditions</router-link> &bull;
      <router-link to="/privacy">Privacy Policy</router-link>
    </div>



    </div>
  </v-app>
</template>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500&display=swap');

.fixedBanner {
  /* position: fixed; */
  /* bottom: 0; */
  /* left: 0; */
  width: 100%;
  height: 140px;
  background-color: #fff; /* Adjust the background color as needed */
  border-top: 4px solid #e5e5e5;
  z-index: 9999; /* Adjust the z-index value to ensure the banner stays on top */
  font-family: 'M PLUS Rounded 1c', sans-serif;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}


.bannerContent {
  margin: auto;
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 140px;
  overflow: visible;
}






  #nav {
    display: none;
  }

  .signInInfo {
    font-size: 0.8em;
  }

  .signInInfo a {
    color: #fff;
    text-decoration: underline;
  }

  .buttonNoUppercase {
    text-transform: none;
  }

  .buttonCapitalise {
    text-transform: uppercase;
  }

  .materialDesignButton {
    color:#fff;
    border-radius:5px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size:1.04em;
    letter-spacing: 0.05em;
    width: auto;
    transition: 0.3s;
  }

  .materialDesignButton:hover {
    opacity: 0.7;
  }


  .hideOnIndex {
    display: none;
  }

  hr {
    width: 1000px;
    margin: 2em auto;
    border: 1px solid #dedede;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  h1 {
    font-size: 6em;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 2em;
  }

  h6 {
    color: #000;
    font-size: 3em;
    padding: 20px;
  }

  .subscribeTable {
    width: 1000px;
    margin: auto;
  }

  table {
    width:100%;
    text-align: center;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    border-collapse: collapse;
  }

  table td, table th {
    border: solid 2px #dedede;
    padding: 20px;
  }

  table thead {
    font-size: 1.6em;
  }

  table th:first-child {
    border-left: 0px;
  }

  table th:last-child {
    border-right: 0px;
    background-color: #edf2f4;
  }




  .fullScreen {
    background-color: rgba(0,0,0,0);
    height:calc(100vh - 140px);
    background: radial-gradient(circle at calc(50vw + 400px), rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.75) 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .scoreBar {
    height: 40px;
    width: calc(100% - 100px);
    display:inline-flex;
    float:left;
    align-items: center;
  }

  .livesRemaining {
    height: 40px;
    width: auto;
    display:inline-flex;
    float:right;
    align-items: center;
  }

  .progressContainer {
    height: 40px;
    align-items: center;
  }

  .indexBoxContent {
    width: 1000px;
    margin: auto;
    padding: 0 0 20px 0;
    text-align: left;
  }

  .indexBox {
    width: 1000px;
    padding: 20px 0 0 0;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .demoBox {
    text-align:center;
    border: 2px solid #dedede;
    border-radius:20px;
    width: 1000px;
    padding: 20px;
    margin: auto;
    overflow: hidden;
  }

  .demoBox hr {
    margin: 20px 0 10px -20px;
    padding: 0;
  }

  .whatNextBox {
    text-align:left;
    border: 2px solid #dedede;
    border-radius:20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dollContainer {
    width:220px;
    height:400px;
    float:right
  }

  .googleButton {
    cursor: pointer;
  }

  .colour-green {
    background-color: #d8f3dc !important;
  }

  .colour-red {
    background-color: #f5cac3 !important;
  }

  .resultIconContainer {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    float:left;
    margin: 0;
    display:inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    padding: 0px 20px;
  }

  .grid-container > div {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .keyboardInput {
    width: 300px;
    margin: auto;
  }


  /* Animation */
  .arrowJump {
    transform: translate(0, -50%);
    animation: jump 5s infinite;
    cursor: pointer;
  }

  @keyframes jump {
      0% { transform: translate(0, -50%) }
      70% { transform: translate(0, -150%) }
      100% { transform: translate(0, -50%) }
  }

  .hideOnDesktop {
    display: none;
  }

  .hideOnMobile {
    display:block;
  }

  .hideWhileOverlay {
    visibility: hidden;
  }

  .googleButtonMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .legalPages {
    font-size: 0.8em;
    color: #383838;
    margin: 0 0 20px 0;
  }

</style>




<style scoped>
  @media screen and (max-width: 1024px) {

    .signInInfo {
      margin: 10px 0 0 0;
    }


    hr {
      width: 100vw;
      margin: 2em auto;
      border: 1px solid #dedede;
      margin: 0px auto;
    }

    h1 {
      font-size: 3em;
      margin: 0;
      padding: 0;
    }

    h4 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.4em;
    }

    h6 {
      color: #000;
      font-size: 3em;
      padding: 20px;
    }

    #nav {
      display: none;
    }

    .domainHeaderMobile {
      color: #dedede
    }

    .fullScreen {
      height:calc(100vh);
      background: rgb(56,56,56);
      background: linear-gradient(0deg, rgba(56,56,56,0.7343312324929971) 0%, rgba(56,56,56,0.8267682072829132) 64%, rgba(56,56,56,1) 100%);
      color: #fff;
      display: block;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .googleButtonMobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .dollContainer {
      width:220px;
      display:none;
    }

    .dollContainerMobile {
      display: block;
      height: 100vh;
      background-image: url(../assets/doll.png);
      background-size: 100%;
      background-position: center 0;
      background-repeat: no-repeat;
      position: relative;
      overflow:auto;
      z-index: 9;
    }


    .arrowContainer {
      display: none;
    }

    .indexBoxContent{
      width: 100vw;
      /* padding: 10px; */
      text-align: center;
    }

    .mobileTextInfo {
      text-align: center;
      padding: 10px;
    }

    .indexBox {
      width: 100vw;
      padding: 10px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .inputHeight {
      height: auto;
      text-align: center;
      justify-content: center;
    }

    .demoBox {
      text-align:center;
      border: 2px solid #dedede;
      border-radius:20px;
      width: calc(100vw - 20px);
      padding: 20px;
      margin: 10px;
    }

    .whatNextBox {
      text-align: center;
      width: calc(100vw - 20px);
      margin: 10px;
    }

    .hideOnDesktop {
      display: block;
    }

    .hideOnMobile {
      display: none;
    }

  }











  /* tablet */
  @media only screen and (min-width:641px) and (max-width:961px) {


    .fullScreen {
      height:calc(65vh);
      background: rgb(56,56,56);
      background: linear-gradient(0deg, rgba(56,56,56,0.5) 0%, rgba(56,56,56,0.8) 64%, rgba(56,56,56,1) 100%);
      color: #fff;
      display: block;
      flex-direction: column;
      align-items: center;
    }

    .dollContainerMobile {
      display: block;
      height: 100vh;
      background-image: url(../assets/doll.png);
      background-size: 40%;
      background-repeat: no-repeat;
      position: inherit;
      overflow:auto;
      z-index: 1000;
    }





  }
</style>












<script src="fitty.min.js"></script>














<script>
import {db} from '../firebase/db';
import firebase from 'firebase';
  import fitty from 'fitty';
  import Emoji from '@/components/Emoji.vue'
  import IndexChart from '@/components/IndexChart.vue'

export default {
  title: "Study & Practice Russian Cases",
  components: {
      IndexChart,
      Emoji
    },
  data() {
    return {
      score: 0,
        lives: 3,
        type: 'number',
        number: 9999,
        selector: '#scrollTo',
        selections: ['#scrollTo'],
        selected: 'Button',
        elements: ['Button', 'Radio group'],
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
        absolute: false,
        overlay: false,
        result: '',
        keyboardInput: false,
        keyboardEntered: "",

        freshnessValues: [72, 84, 42, 58, 68, 31],

        user: [],
        uid: "",
        username: "",

        currentQuestion: 0,
        buttons: [],
        ended: false,
        overallResult: "",
        wrongRecap: [],

        questions: [
          { canType:true, question: "Choose the correct declension to fit the sentence.", russian: "Я читаю _", english: "I am reading the 'book'", answers: ["КНИГА", "КНИГИ", "КНИГЕ", "КНИГУ", "КНИГОЙ"], correct: "КНИГУ" },
          { canType:true, question: "Choose the correct form of the word 'green' to fit the sentence.", russian: "Я пишу _ ручкой", english: "I'm writing with a _ pen", answers: ["ЗЕЛЁНЫМИ", "ЗЕЛЁНЫЕ", "ЗЕЛЁНОЕ", "ЗЕЛЁНОГО", "ЗЕЛЁНОЙ", "ЗЕЛЁНЫМ"], correct: "ЗЕЛЁНОЙ" },
          { canType:false, question: "Which sentence is missing the word 'newspaper' in the prepositional case (газете)?", russian: "", english: "", answers: ["Я читаю _", "Где _?", "Он прочитал это в _"], correct: "Он прочитал это в _" },
          { canType:true, question: "Choose the correct form of the word 'good' to fit the sentence.", russian: "Мне нравится говорить с _ друзьями", english: "I like talking with _ friends", answers: ["ХОРОШИМИ", "ХОРОШИМ", "ХОРОШЕМУ", "ХОРОШУЮ", "ХОРОШЕЕ", "ХОРОШЕГО"], correct: "ХОРОШИМИ" },
          { canType:false, question: "Is this sentence correct?", russian: "Я иду к стул", english: "I'm walking towards the chair", answers: ["Yes", "No"], correct: "No" },
          { canType:false, question: "Which gender is the word 'отец' (father)?", russian: "", english: "", answers: ["Neuter", "Feminine", "Masculine"], correct: "Masculine" },
          { canType:false, question: "Which sentence is missing the word 'shop' in the prepositional case (магазине)?", russian: "", english: "", answers: ["Мне нужно пойти в _", "Где _?", "Что вы обычно покупаете в _?"], correct: "Что вы обычно покупаете в _?" },
          { canType:true, question: "Choose the word 'train' in the accusative case.", russian: "", english: "", answers: ["ПОЕЗДЕ", "ПОЕЗДА", "ПОЕЗДУ", "ПОЕЗДОМ", "ПОЕЗД"], correct: "ПОЕЗД" },
          { canType:false, question: "Is this sentence correct?", russian: "У меня есть вид на город благодаря новому окну", english: "I have a view of the city thanks to the new window", answers: ["Yes", "No"], correct: "Yes" },
          { canType:true, question: "Choose the word 'famous' in the accusative (animate), feminine form.", russian: "", english: "", answers: ["ИЗВЕСТНУЮ", "ИЗВЕСТНОЙ", "ИЗВЕСТНЫХ", "ИЗВЕСТНЫМ", "ИЗВЕСТНЫЙ", "ИЗВЕСТНОЕ"], correct: "ИЗВЕСТНУЮ" },
        ]
    }
  },
  methods: {

    changeInput() {
        this.keyboardInput = !this.keyboardInput
      },

      isOverlay() {
        if (this.overlay) {
          return "hideWhileOverlay"
        }
      },

      alterQuestion(input) {
        if (input.substring(0, 6) == "Choose" && this.keyboardInput) {
          return "Type" + this.questions[this.currentQuestion].question.slice(6);
        } else {
          return this.questions[this.currentQuestion].question;
        }
      },

      checkAnswer(input){
        this.overlay = !this.overlay
        input = input.toLowerCase()
        this.wrongRecap = []

        if (input == this.questions[this.currentQuestion].correct.toLowerCase()) {
          this.result = 'Correct'
          this.score += 10;
        } else {
          this.result = 'Wrong'
          this.wrongRecap = [this.questions[this.currentQuestion].question,
                             "You answered: " + input,
                             "The correct answer was: " + this.questions[this.currentQuestion].correct.toLowerCase()];
          this.lives--;
        }

        this.keyboardEntered = "";

        if (this.currentQuestion == 9) {
          //end, win
          this.ended = true;
          this.overallResult = "win";
        } else if (this.lives == 0) {
          //end, lose
          this.ended = true;
          this.overallResult = "lose";
        } else {
          this.currentQuestion++;
          this.buttons = this.questions[this.currentQuestion].answers;
        }

      },

      resetDemo(){
        this.ended = false;
        this.currentQuestion = 0;
        this.score = 0;
        this.lives = 3;
        this.buttons = this.questions[this.currentQuestion].answers;
      },




      getBackgroundColour() {
        return (this.result == 'Correct') ? 'colour-green' : 'colour-red'
      },

    async googleSignIn() {
      const provider = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(provider).then(() => {

        firebase.auth().onAuthStateChanged((user) => {
          if (user) {

            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            this.uid = user.uid;
            this.displayName = user.displayName;
            this.email = user.email;
            this.photoURL = user.photoURL;
            this.emailVerified = user.emailVerified;
            this.signedIn = true;
            this.username = user.displayName;



          } else {
            // User is signed out
            // ...
            this.signedIn = false;
          }
        });

      }).catch(err => {
        console.log(err);
      });


      var userData = await db.collection('Users').doc(this.uid).get();
      userData = userData.data();
      if (userData == null) {
        console.log("New user");


        await db.collection("Users").doc(this.uid).set({
          admin: false,
          username: this.username,
          timeZone: 0,
          totalStreak: 0,
          weeklyStreak: [0, 0, 0, 0, 0, 0, 0],

          lessonsCompleted: [0, 0, 0, 0, 0, 0],
          lessonsCompletedAdjectives: [0, 0, 0, 0, 0, 0],
          //decayFactor, lastReset, resetCounter ...
          //for each ^, mascNoun, mascAdj, femNoun, femAdj, neutNoun, neutAdj, pluAdj
          nomForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          accForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          prepForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          genForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          datForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          instForgettingCurve: [-999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0, -999, -999, 0],
          identifyGenderForgettingCurve: [-999, -999, 0],


          nomMascNounPastPerformance: [],
          nomMascAdjPastPerformance: [],
          nomFemNounPastPerformance: [],
          nomFemAdjPastPerformance: [],
          nomNeutNounPastPerformance: [],
          nomNeutAdjPastPerformance: [],
          nomPluAdjPastPerformance: [],

          accMascNounPastPerformance: [],
          accMascAdjPastPerformance: [],
          accFemNounPastPerformance: [],
          accFemAdjPastPerformance: [],
          accNeutNounPastPerformance: [],
          accNeutAdjPastPerformance: [],
          accPluAdjPastPerformance: [],

          prepMascNounPastPerformance: [],
          prepMascAdjPastPerformance: [],
          prepFemNounPastPerformance: [],
          prepFemAdjPastPerformance: [],
          prepNeutNounPastPerformance: [],
          prepNeutAdjPastPerformance: [],
          prepPluAdjPastPerformance: [],

          genMascNounPastPerformance: [],
          genMascAdjPastPerformance: [],
          genFemNounPastPerformance: [],
          genFemAdjPastPerformance: [],
          genNeutNounPastPerformance: [],
          genNeutAdjPastPerformance: [],
          genPluAdjPastPerformance: [],

          datMascNounPastPerformance: [],
          datMascAdjPastPerformance: [],
          datFemNounPastPerformance: [],
          datFemAdjPastPerformance: [],
          datNeutNounPastPerformance: [],
          datNeutAdjPastPerformance: [],
          datPluAdjPastPerformance: [],

          instMascNounPastPerformance: [],
          instMascAdjPastPerformance: [],
          instFemNounPastPerformance: [],
          instFemAdjPastPerformance: [],
          instNeutNounPastPerformance: [],
          instNeutAdjPastPerformance: [],
          instPluAdjPastPerformance: [],

          identifyGenderPastPerformance: []


        })

        var stats = await db.collection('Statistics').doc('STATISTICS').get();
        stats = stats.data();

        stats.numberOfUsers++;

        await db.collection('Statistics').doc('STATISTICS').update({
          numberOfUsers: stats.numberOfUsers
        });





        this.$router.push({ name: 'Dashboard'})


      } else {
        this.userData = userData;
        console.log(userData);

        this.$router.push({ name: 'Dashboard'})
      }

    },
  },
  beforeMount() {

    this.buttons = this.questions[this.currentQuestion].answers;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.displayName = user.displayName;
        this.email = user.email;
        this.photoURL = user.photoURL;
        this.emailVerified = user.emailVerified;
        this.signedIn = true;

        this.username = user.displayName;

        // console.log("UNCOMMENT THIS WHEN READY TO FINISH:");
        this.$router.push({ name: 'Dashboard'})

      } else {
        // User is signed out
        // ...
        this.signedIn = false;
      }
    });
  },
  computed: {
        target () {
          const value = this[this.type]
          if (!isNaN(value)) return Number(value)
          else return '#scrollTo'
        },
        options () {
          return {
            duration: this.duration,
            offset: this.offset,
            easing: this.easing,
          }
        },
        element () {
          if (this.selected === 'Button') return this.$refs.button
          else if (this.selected === 'Radio group') return this.$refs.radio
          else return null
        },
      },
}
</script>
