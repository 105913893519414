<template>
  <div class="polarChart">
    <!-- <p>{{ freshnessValues }} test</p> -->
    <!-- <v-btn @click="addData()">Click</v-btn> -->
    <apexcharts height="300"  type="polarArea" :options="chartOptions" :series="series"></apexcharts>
  </div>
</template>

<style scoped>
  .polarChart {
    /* padding: 0 20px 0 0; */
    align-items: center;
  }
</style>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Chart',
  components: {
    apexcharts: VueApexCharts,
  },
  props: ['freshnessValues'],
  data: function() {
    return {
      series: this.freshnessValues,
      plotOptions: {
         polarArea: {
           dataLabels: {
              offset: 30
            }
         }
      },
      chartOptions: {
        labels: ['Nominative', 'Accusative', 'Prepositional', 'Genitive', 'Dative', 'Instrumental'],
        colors:['#403d39', '#ffbe0b', '#00b4d8', '#e73265', '#90be6d', '#ada7c9'],
        legend: {
          show: false
        },
        yaxis: {
          max: 100,
          show: false
        },
        xaxis: {
              categories: ['Nom.', 'Acc.', 'Prep.', 'Gen.', 'Dat.', 'Inst.']
            },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
              return Math.round(opts.w.globals.series[opts.seriesIndex]) + "% " + opts.w.globals.labels[opts.seriesIndex]
          },
          background: {
            enabled: true,
            borderRadius:2,
          },
          style: {
            colors: ['#403d39', '#ffbe0b', '#00b4d8', '#e73265', '#90be6d', '#ada7c9'],
          }
        },
        tooltip: {
          // enabled: false
        },
        chart: {
          type: 'polarArea',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 1
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: '100%'
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  methods: {
    addData(){
      this.updateChart();
    },
    updateChart() {

        // In the same way, update the series option
        this.series = [{
          data: this.freshnessValues
        }]
      }
  },
  // computed: {
  //   series() {
  //     console.log("");
  //       return this.freshnessValues;
  //   }
  // }
}
</script>
